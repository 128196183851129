<template>
  <v-combobox
    v-model="model"
    v-bind="p"
    @focus="fetch"
    @update:modelValue="check"
    :loading="fetching"
    :items="items"
    :hint="`Maximum of ${max} tags`"
    color="blue-grey-lighten-2"
    chips
    closable-chips
    multiple
  >
    <template v-slot:chip="{ props, item }">
      <v-chip v-bind="props" :text="item.raw"></v-chip>
    </template>

    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="item.raw"></v-list-item>
    </template>
  </v-combobox>
</template>

<script lang="ts">
import { get, isString, pick } from "lodash";
import { defineComponent } from "vue";
import { VCombobox } from "vuetify/components";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";

const baseProps = get(VCombobox, "props", {});
const basePropKeys = Object.keys(baseProps);

export default defineComponent({
  name: "TagSelect",
  emits: ["update:modelValue"],
  props: {
    ...baseProps,
    modelValue: { type: Array as PropType<string[]> },
    max: { type: [Number, String], default: 5 },
    types: { type: [String, Array] },
    label: { type: String, default: "Add Tag" },
    width: { type: [Number, String] },
    disabled: { type: Boolean, default: false },
  },
  setup(props) {
    const enabled = ref(false);

    const queryVars: any = props.types
      ? { types: isString(props.types) ? [props.types] : props.types }
      : {};

    const query = useQuery<{ tags: string[] }>(
      gql`
        query ($types: [String!]) {
          tags(types: $types)
        }
      `,
      queryVars,
      { fetchPolicy: "no-cache", enabled }
    );

    const fetch = async () => {
      enabled.value = true;
    };

    const items = computed<string[]>(() => query.result.value?.tags || []);

    return {
      fetching: query.loading,
      items,
      fetch,
    };
  },
  methods: {
    check() {
      this.$nextTick(() => {
        const max = Number(this.max);
        while (this.model.length > max) {
          (this.model as string[]).shift();
        }
      });
    },
  },
  computed: {
    p() {
      return pick(this.$props, basePropKeys);
    },
    model: {
      set(v: string[]) {
        this.$emit("update:modelValue", v);
      },
      get() {
        return this.modelValue || [];
      },
    },
  },
});
</script>
